import React, { useEffect, useState } from "react";
import SignIn from "../signIn/signIn";
import SignUp from "../signUp/signUp";
import { useAuth } from "../../hooks/useAuth";
import MessageOffcanvas from "../MessageOffcanvas";
import SetCurrencyModal from "../UnAuthCurrencyModal/UnAuthCurrencyModal";
import {
  ADMIN_FRONTEND_LOGGED_IN_ID,
  AUTH_MODAL_TABS,
  DEFAULT_CURRENCY,
  SELECTED_CURRENCY,
  WALLET_MODAL_TABS,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { reducerConst } from "../../constants/storeConstants";
import { fetchUserDetails } from "../../service/fetchUserDetails";
import AuthModal from "../AuthModal";
import { useAuthModal } from "../../hooks/useAuthModal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import NewWallet from "../../pages/newWallet/Index";
import { useWalletModal } from "../../hooks/useWallet";
import { ROUTES_CONST } from "../../constants/routeConstant";
import UserBalanceComp from "./UserBalanceComp";
import { socket } from "../../service/socket";
import { useTranslation } from "react-i18next";
import LogoutModal from "../logoutModal/LogoutModal";
import { useLogoutModal } from "../../hooks/useLogout";
import ReferralModal from "../AuthModal/SignUpTab/ReferralModal";
import { getAPIAuth } from "../../service/apiInstance";
import { fantasyAddUserData, setSocialLinksData, userFullDetailsAction } from "../../store/action";
import { getFantasyAuth } from "../../fantasy/fantasyServices";
import { errorToaster } from "../../utils/toaster";
import { useFantasyLoader } from "../../hooks/useFantasyLoader";
import OpenBetModal from "../openBetModal/OpenBetModal";

const AppHeader = () => {
  const { token } = useAuth();
  const [isUnAuthCurrency, setIsUnAuthCurrency] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const [showBets, setShowBets] = useState(false)
  const { setShowFantasyLoader } = useFantasyLoader();
  const [socialLinks, setSocialLinks] = useState([])
  const [currency, setCurrency] = useState();
  const [selectedTab, setSelectedTab] = useState("");
  const dispatch = useDispatch();
  const { setAuthModalObject } = useAuthModal();
  const { setWalletModalObject } = useWalletModal()
  const { t } = useTranslation()
  const desktopHeader = t("header", { returnObjects: true }) //string has to be same as language json key 
  const { setIsLogoutOpen } = useLogoutModal()
  // const adminID = useLocalStorage(ADMIN_LOGGED_IN_ID, "")
  const adminID = localStorage.getItem(ADMIN_FRONTEND_LOGGED_IN_ID)
  const userFullDetails = useSelector(state => state?.userFullDetails)
  const navigate = useNavigate();



  /// userDetails for user logging in through login signup
  const getUserInfo = async () => {
    try {
      const res = await getAPIAuth(`user/profile/${adminID}`, token)
      if (res?.data?.data?.success) {
        dispatch(userFullDetailsAction(res.data.data.data))
      }
    } catch (error) {
      //error
    }
  }

  //userDetails from fantasy profile
  const getProfileData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`, token);
      console.log('getProfileData', res)
      if (res.data.success) {
        dispatch(fantasyAddUserData(res.data.data));
      }
    } catch (error) {
      console.log("---------------------->>>>>>>", error)
    }

  };


  const getSocialLinks = async () => {
    try {
      const res = await getAPIAuth(`getSocialMediaLinks`, token)
      if (res?.data?.data?.success) {
        setSocialLinks(res?.data?.data?.data)
        dispatch(setSocialLinksData(res?.data?.data?.data))
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    if (token) {
      getProfileData()
      getSocialLinks()
      //eslint-disable-next-line
    } else {
      // alert("token expired")
    }
  }, [token])

  useEffect(() => {
    if (token) {
      if (adminID) {
        getUserInfo()
      }
    }
  }, [token])

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const res = await fetchUserDetails(token);
        dispatch({
          type: reducerConst.USER_DETAILS,
          payload: res?.data?.data ? res.data.data : {},
        });
      };
      // getData();
    }
  }, [token]);

  useEffect(() => {
    const item = JSON.parse(localStorage.getItem(SELECTED_CURRENCY));
    if (!!userDetails?.usercurrency) {
      setCurrency(userDetails.usercurrency);
    } else if (item?.id) {
      setCurrency(item?.code);
    } else {
      setCurrency(DEFAULT_CURRENCY.code);
    }
  }, [
    JSON.parse(localStorage.getItem(SELECTED_CURRENCY))?._id,
    userDetails?.id,
    token,
    userDetails?.usercurrency,
  ]);

  const sideAppHandler = () => {
    const exists = document.body.classList;
    if (exists["value"] === "asideBar") {
      document.body.classList.remove("asideBar");
    } else {
      document.body.classList.add("asideBar");
    }
  };
  // function for logout
  const logoutFunction = () => {
    setIsLogoutOpen(true);
  };
  return (
    <>
      <div className="container-fluid navbar  position-fixed top-0 w-100 ">
        <div className="row align-items-center w-100 mx-0">
          <div className="col-auto ps-0 d-md-block  d-none">
            <Link
              // to={ROUTES_CONST.CASINO}
              to={ROUTES_CONST.INDEX}
              className="text-decoration-none d-flex align-items-center logoNav "
            >
              <img
                className="image "
                style={{
                  width: "50px",
                  height: "50px"
                }}
                // src="assets/img/header/logonav.svg"
                src="assets/img/heroLogo.jpeg"
                alt="logo"
              />
            </Link>
          </div>
          <div className="col-auto ps-0 d-md-none d-block">
            <div
              onClick={sideAppHandler}
              className="text-decoration-none d-flex align-items-center logoNav "
            >
              <img
                className="image"
                src="assets/img/heroLogo.jpeg"
                alt="logo"
              />
            </div>
          </div>
          <div className="col px-0">
            {token ? (
              <div className="row mx-0 align-items-center justify-content-end justify-content-xl-between dnone">
                <div className="col-auto order-2 order-xl-1 px-0 d-none d-sm-block">
                  <ul className="list-unstyled mb-0">
                    <li>
                      <div className="dropdown giftDrowpdown ">
                        <a
                          className="dropdown-toggle giftBtnDrop text-decoration-none d-flex"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="position-relative d-flex align-items-center">
                                <div className="dropImg d-inline-flex">
                                  <img
                                    className="image"
                                    src="assets/img/sidebar/support.png"
                                    alt="gift"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col ps-0">
                              <div className="dropbtn">
                                Support
                              </div>
                            </div>
                          </div>
                        </a>
                        <ul className="dropdown-menu dropMenunav ">
                          {
                            socialLinks?.length > 0 ?
                              socialLinks?.map((item) => (
                                <li className="dropdown-item dropItem d-flex px-0 position-relative" key={item?._id}>
                                  <Link to={item?.link} target="_blank" className="row mx-0 w-100 align-items-center text-decoration-none">
                                    <div className="col px-0">
                                      <div className="row mx-0 align-items-center">
                                        <div className="col-auto px-0">
                                          <div className="icongift">
                                            <img
                                              className="image"
                                              src={item?.image}
                                              alt="bouns"
                                            />
                                          </div>
                                        </div>
                                        <div className="col">
                                          <div className="bonusTitle d-flex align-items-center">
                                            {item?.name}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              ))
                              : 'no data'
                          }
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-auto d-xl-none d-block px-0 order-1 order-xl-2">
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0">
                    <li className="d-flex justify-content-center">
                      <UserBalanceComp setShowBets={setShowBets} />
                    </li>
                  </ul>
                </div>
                <div className="col-auto d-xl-block d-none order-xl-2">
                  <ul className="list-unstyled d-flex align-items-center gap-3 mb-0 ">
                    <li className="d-flex justify-content-center">
                      <UserBalanceComp setShowBets={setShowBets} />
                    </li>
                    <li>
                      <div
                        onClick={() => setWalletModalObject((prev) => ({
                          isWalletOpen: true,
                          selectedTab: WALLET_MODAL_TABS.DEPOSIT
                        }))}
                        className=" text-decoration-none d-flex align-items-center justify-content-center navBankBtn customBtn cursor-pointer"
                      >
                        {desktopHeader?.BANK}
                      </div>
                    </li>
                    <li>
                      <Link to={ROUTES_CONST.BUY_CRYPTO_URL}
                        target="_blank"
                        className=" text-decoration-none d-flex align-items-center justify-content-center buyCripto cursor-pointer"
                      >
                        {desktopHeader.BUY_CRYPTO}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-auto px-0 d-xl-block d-none order-xl-3">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="d-flex align-items-center gap-3">
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <div className="dropdown dropdownAccount">
                        <span
                          className="dropdown-toggle accountDrop text-decoration-none d-flex"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="dropImg d-flex">
                                <img
                                  className="image"
                                  src="assets/img/header/account.svg"
                                  alt="gift"
                                />
                              </div>
                            </div>
                            <div className="col ps-0 d-lg-block d-none">
                              <div className="dropbtn "> {desktopHeader.ACCOUNT} </div>
                            </div>
                          </div>
                        </span>
                        <ul className="dropdown-menu dropmenu mb-0 accountDrop rounded-3 p-3">
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({ type: "WALLET", payload: "Wallet" })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/wallet.png"
                                    alt="Wallet"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.Wallet}

                              </span>
                            </NavLink>
                          </li>

                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({ type: "CONVERTER", payload: "Converter" })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/switch.png"
                                    alt="Wallet"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                Converter

                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <span
                              // to={ROUTES_CONST.FANTASY_WALLET}
                              onClick={() => {
                                if (!!Object.values(userFullDetails).length) {
                                  if (!!userFullDetails.isCricket) {

                                    setShowFantasyLoader(true)
                                    navigate(ROUTES_CONST.FANTASY_WALLET)
                                    setTimeout(() => {
                                      setShowFantasyLoader(false);
                                    }, 3000);
                                  } else {
                                    // errorToaster("You are not authorized  ")
                                    errorToaster("You don't have access to this section ")
                                  }
                                }
                              }}
                              className='text-decoration-none'
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/wallet.png"
                                    alt="Wallet"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.FantasyWallet}

                              </span>
                            </span>
                          </li>
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({
                                  type: "AFFILIATE",
                                  payload: "Affiliate",
                                })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/affiliate.png"
                                    alt="Affiliate"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.Affiliate}

                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({
                                  type: "DEPOSITS",
                                  payload: "Deposits",
                                })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/deposits.png"
                                    alt="Deposits"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.Deposits}

                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({
                                  type: "WITHDRAW",
                                  payload: "Withdrawals",
                                })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/withdraw.png"
                                    alt="Withdrawals"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>

                                {desktopHeader.ACCOUNT_OPTIONS.Withdrawals}
                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({
                                  type: "WITHDRAW",
                                  payload: "Transactions",
                                })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/converterIcon.png"
                                    alt="Transactions"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.Transactions}


                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <NavLink
                              to={ROUTES_CONST.ACCOUNT}
                              className='text-decoration-none'
                              onClick={() =>
                                dispatch({ type: "SETTING", payload: "Settings" })
                              }
                            >
                              <span className="dropdown-item profileBtn d-flex align-items-center">
                                <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                  <img
                                    src="assets/img/sidebar/setting.png"
                                    alt="Settings"
                                    className="h-100 w-100 img-fluid"
                                  />
                                </span>
                                {desktopHeader.ACCOUNT_OPTIONS.Settings}

                              </span>
                            </NavLink>
                          </li>
                          <li className="accountContent">
                            <span
                              className="dropdown-item profileBtn d-flex align-items-center cursor-pointer"
                              onClick={logoutFunction}
                            >
                              <span className="accountbtnIcon d-flex align-items-center justify-content-center">
                                <img
                                  src="assets/img/sidebar/logout.png"
                                  alt="Log Out"
                                  className="h-100 w-100 img-fluid"
                                />
                              </span>
                              {desktopHeader.ACCOUNT_OPTIONS.Log_Out}

                            </span>
                          </li>
                        </ul>
                      </div>

                      {/* search offcanvas */}
                      {/* <NavLink
                          className="searchBtn d-flex align-items-center"
                          to={ROUTES_CONST.SLOT_PAGE}
                        >
                          <img
                            className="image"
                            src="assets/img/header/search.svg"
                            alt="search"
                          />
                        </NavLink> */}
                      {/* message offacanvas */}
                      <span
                        className=" searchBtn d-flex align-items-center"
                        // data-bs-toggle="offcanvas"
                        // href="#offcanvasExample3"
                        // role="button"
                        // aria-controls="offcanvasExample"
                        data-bs-toggle="offcanvas"
                        href="#message"
                        role="button"
                        aria-controls="message"
                        onClick={() => {
                          socket.emit('joinRoom', { msg: null })
                          document.body.classList.toggle('showMsgOffcanvas')
                        }}
                      >
                        <img
                          className="image"
                          src="assets/img/header/chat.svg"
                          alt="search"
                        />
                      </span>

                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row align-items-center justify-content-end w-100 dnone">
                <div className="col-auto">
                  <span
                    className="text-decoration-none d-flex align-items-center loginBtn cursor-pointer"
                    onClick={() =>
                      setAuthModalObject((pre) => ({
                        isAuthOpen: true,
                        selectedTab: AUTH_MODAL_TABS.LOG_IN,
                      }))
                    }
                  >
                    {desktopHeader?.LOGIN}
                  </span>
                </div>
                <div className="col-auto pe-0">
                  <span
                    className="text-decoration-none d-flex align-items-center registerBtn cursor-pointer"
                    onClick={() =>
                      setAuthModalObject((pre) => ({
                        selectedTab: AUTH_MODAL_TABS.SIGN_UP,
                        isAuthOpen: true,
                      }))
                    }
                  >
                    {desktopHeader?.REGISTER}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <SignUp />
      <SignIn />
      <SetCurrencyModal
        isOpen={isUnAuthCurrency}
        setIsOpen={setIsUnAuthCurrency}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <AuthModal />
      <NewWallet />
      <MessageOffcanvas />
      <LogoutModal />
      <ReferralModal
      />
      <OpenBetModal
        showBets={showBets}
        setShowBets={setShowBets}
      />
    </>
  );
};

export default AppHeader;