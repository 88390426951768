import React from "react";

const Poaster = () => {
    return (
        <div className="col-lg-6 mb-lg-0 mb-4">
            <div className="row">
                <div className="col-12">
                    <div className="dambleUser d-flex px-sm-0 px-4">
                        <img src="assets/img/herodamble.png" alt="" />
                    </div>
                </div>
                <div className="col-12">
                    <div className="userName fw-bolder text-white text-center mt-4">
                        WELCOME TO Baaziguru <br />{" "}
                        <span className="fw-normal">
                            YOUR GAMING JOURNEY STARTS NOW
                        </span>{" "}
                    </div>
                </div>
                <div className="col-12">
                    <div className="row align-items-center mx-0 mt-md-5">
                        <div className="col">
                            <div className="line" />
                        </div>
                        <div className="col-auto">
                            <div className="logoLine d-flex justify-content-center">
                                <img src="assets/img/whitelogo.png" alt="" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="line" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Poaster