import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { getAPI } from '../../service/apiInstance';
import { Link, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { ROUTES_CONST } from '../../constants/routeConstant';
import { setGameId } from '../../store/action';
import { useDispatch } from 'react-redux';

const SportsCards = () => {
  const navigate = useNavigate()
  const [data, setData] = useState('')
  const [loader, setLoader] = useState(true)
  const dispatch = useDispatch();

  console.log("datdataa", data);

  // const getMatchSeries = async () => {
  //   try {
  //     const res = await getAPI('bet/games')
  //     if (res.data.data.success) {
  //       setData(res.data.data.data)
  //       // setSelectedCat(res.data.data.data[0])
  //     }
  //   } catch (error) {

  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   getMatchSeries()
  // }, [])
  // Function for getting sports
  const getSports = async () => {
    setLoader(true)
    try {
      // const res = await getAPI('crypto/sports-book')
      const res = await getAPI('casino/getProviders');
      console.log("dsfdsfsf", res);
      if (res?.data?.data?.success) {
        // setData(res?.data?.getData)
        setData(res?.data?.data?.data)
        // setSelectedCat(res.data.data.data[0])
      }
    } catch (error) {
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    getSports()
  }, [])
  return (
    <div className="container-fluid toprated-games-main sportsBookFluid px-0 position-relative">
      <div className="toprated-games">
        <div className="row position-relative mx-0">
          <div className="col-12 px-0">
            <div className="row">
              <div className="col-auto">
                <div className="diceBox"><img src="assets/img/index/footballhead.png" alt="" />PROVIDER LIST <span className='cursor-pointer' onClick={() => navigate(ROUTES_CONST.SPORTS)}>All Casino</span> </div>
              </div>
            </div>
          </div>
          <div className="col-12 games-card-main px-0">
            <div className="mySwiper sportsSwiper">
              <Swiper className="swiper"
                navigation={{
                  nextEl: ".sportsNext",
                  prevEl: ".sportsPrev",
                }}
                // mousewheel={true}
                // keyboard={true}
                modules={[Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                  },
                  430: {
                    slidesPerView: 3,
                    spaceBetween: 8,
                  },
                  576: {
                    slidesPerView: 4,
                    spaceBetween: 8,
                  },
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 8,
                  },
                  992: {
                    slidesPerView: 6,
                    spaceBetween: 8,
                  },
                  1200: {
                    slidesPerView: 6,
                    spaceBetween: 8,
                  },
                  1400: {
                    slidesPerView: 7,
                    spaceBetween: 10,
                  },
                  1500: {
                    slidesPerView: 8,
                    spaceBetween: 10,
                  }
                }}>
                <div className="swiper-wrapper">
                  {!loader ?
                    <>
                      {data?.length > 0 ?
                        <>
                          {data?.map((item) => (
                            <SwiperSlide key={item?._id} className="swiper-slide" onClick={() => {
                              // dispatch(setGameId(item?.gameId))
                              navigate(ROUTES_CONST.CASINO)
                            }}>
                              <Link to={item?.url} >
                                {/* <div className=""> */}
                                  <div className="casinoImg rounded-2 overflow-hidden cursor-pointer" >
                                    <img src={`https://api.baaziguru.com/providers/${item.image}`}  className="img-fluid w-100 h-100 " alt="CasinoHome" />
                                  </div>
                                  {/* <div className="col-12 px-0 d-flex justify-content-center"><div className="sportsTxt text-uppercase text-center">{item?.fullName}</div></div> */}
                                {/* </div> */}
                              </Link>
                              {/* <Link to={item?.url} className="">
                                <div className="">
                                  <div className="casinoImg rounded-2 overflow-hidden cursor-pointer" >
                                    <img src={`https://api.baaziguru.com/providers/${item.image}`}  className="img-fluid w-100 h-120 " alt="CasinoHome" />
                                  </div>
                                  <div className="col-12 px-0 d-flex justify-content-center"><div className="sportsTxt text-uppercase text-center">{item?.fullName}</div></div>
                                </div>
                              </Link> */}
                            </SwiperSlide>
                          ))}
                        </>
                        : <div className="w-100 h-100">
                          <h6 className="text-light"> No data available</h6>
                        </div>}
                    </>
                    :
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                    </div>}
                </div>
              </Swiper>
              <div className="swiper-button-prev-unique swiper-button-prev-unique1 sportsPrev p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselLeftArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
              <div className="swiper-button-next-unique swiper-button-next-unique1 sportsNext p-0  d-inline-flex align-items-center justify-content-center cursor-pointer"><img src="assets/img/carouselRightArrow.png" alt="prev" className="h-100 w-100 img-fluid" /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SportsCards