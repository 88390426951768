// use this file to add generic constants
export const TOKEN_NAME = "betApp-token"
export const BT_TOKEN_NAME = "bt-token"
export const ADMIN_AUTH_ACCESS = "admin_auth_access" //admin-auth token
export const ADMIN_AUTH_ROLE = "admin_auth_role" //admin-role
export const ADMIN_LOGGED_IN_ID = "admin_log"
export const ADMIN_FRONTEND_LOGGED_IN_ID = "admin_frontend_log"
export const DEFAULT_IMAGE = "assets/img/gd.png"
export const SELECTED_CURRENCY = "currency"
// export const baseURL = 'http://206.189.129.134:4000/api/v1'
export const baseURL = 'https://api.baaziguru.com/api/v1'
// export const adminBaseUrl = "http://206.189.129.134:4000/api/v1"
export const adminBaseUrl = "https://api.baaziguru.com/api/v1"
// export const fantasyBaseUrl = "http://206.189.129.134:7070/api"
export const fantasyBaseUrl = "https://api.baaziguru.com/api"
export const sportsBaseUrl = 'https://api.baaziguru.com/api/v1'
// export const sportsBaseUrl = 'http://206.189.129.134:7070/'
// export const sportsSocketBase = 'http://206.189.129.134:4000'
export const sportsSocketBase = 'https://api.baaziguru.com'
export const tradingBaseUrl = "https://www.algoniti.com/learnotrade/server"
export const USER_LANG = 'user-lang' // has to replace 
export const OPEN_CAGE_API_KEY = '8d5951e1a3ef47afa1ed5ed6a07e80d6'
export const MOBILE_SCREEN_WIDTH = 550
export const FANTASY_TOKEN_NAME = 'fantasy-token'//token is set in fantasy home (static)
export const TRADING_TOKEN_NAME = 'trading-token'//token is set in trading watchlist (static) , there is not token currently 
export const STOCK_USER_ID = "stockUserId"



// Default currency 
export const DEFAULT_CURRENCY = {
    "_id": "64ba08ca7971fec6d7702f6e",
    "name": "United States Dollar",
    "id": "usd",
    "code": "USD",
    "symbol": "$",
    "icon": "https://cdn.onramper.com/icons/tokens/usd.svg",
    "createdAt": "2023-07-21T04:25:46.239Z",
    "updatedAt": "2023-07-21T04:25:46.239Z",
    "isFav": false,
    "type": "currency"
}

export const TABS_OPTIONS = {
    language: "language",
    currency: "currency"
}

export const routeUrl = {
    url1: '',
    url2: '',
    url3: ''
}


export const NEWS_CATE_LIMIT = 10

export const AUTH_MODAL_TABS = {
    SIGN_UP: "signUp",
    LOG_IN: 'logIn'
}

export const WALLET_MODAL_TABS = {
    DEPOSIT: "deposit",
    WITHDRAW: 'withdraw',
    BUY_CRYPTO: 'buyCrypto',
    UPDATE_WITHDRAW_DETAILS: 'update-withdraw-details'
}
