import React, { useEffect, useState } from "react";
import { adminGetAPIAuth, adminPostAPIAuth } from "../../AdminServices";
import './style.css'
import { USER } from "../../AdminConstants/apiEndpoints";
import { succesToaster as successToaster } from "../../../utils/toaster";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { PORTAL, MARKET_PAGE } from "../../AdminConstants/urlPaths";
import { REPORTS } from "../../AdminConstants/apiEndpoints";
import moment from "moment";
import { errorToaster } from "../../../utils/toaster";


const AddMarket = () => {
    const [CatagoriesList, setCatagoriesList] = useState([]);
    console.log("CatagoriesList", CatagoriesList);
    const { state } = useLocation();
    const param = useParams();
    console.log("param", param);

    console.log("dsfgysdguysyuhdfidvdgvfv", state);
    // const findNumberData = CatagoriesList?.find(g => g.name === state.gameCate);

    console.log("findNumberData", state);
    const navigate = useNavigate();
    const adminData = useSelector(state => state.adminData);
    const [stocklist, setStockList] = useState([]);
    const [stockltpvalue, setStockLtpValue] = useState("");
    // console.log("stocklist", stockltpvalue);
    const [formData, setFormData] = useState({
        stockCat: state?.stockId || "",
        gameCate: state?.stockName || "",
        from: moment(state?.from).format("YYYY-MM-DD") || "",
        to: moment(state?.to).format("YYYY-MM-DD") || "",
        no: state?.no || "",
        noFluctuation: state?.noFluctuation || "",
        noSmall: state?.noSmall || "",
        noSmallFluctuation: state?.noSmallFluctuation || "",
        yes: state?.yes || "",
        yesFluctuation: state?.yesFluctuation || "",
        yesSmall: state?.yesSmall || "",
        yesSmallFluctuation: state?.yesSmallFluctuation || "",
        min_bet: state?.min_bet || "",
        max_bet: state?.max_bet || "",
        status: state?.status || "",
        result: state?.result || "",
        resultType: state?.resultType || "",
        name: state?.name || "",
        ToTimme: state?.toTime || "",
        fromTime: state?.fromTime || ""
    });
    const [errorsmessage, setErrorsMessage] = useState({});


    const validate = () => {
        const newErrors = {};

        if (!formData.stockCat) newErrors.stockCat = "Stock Category is required.";
        if (!formData.gameCate) newErrors.gameCate = "Game Category is required.";
        if (!formData.from) newErrors.from = "From date is required.";
        if (!formData.to) newErrors.to = "To date is required.";
        if (!formData.no) newErrors.no = "No value is required.";
        if (!formData.noFluctuation) newErrors.noFluctuation = "No Fluctuation is required.";
        if (!formData.noSmall) newErrors.noSmall = "No Small value is required.";
        if (!formData.noSmallFluctuation) newErrors.noSmallFluctuation = "No Small Fluctuation is required.";
        if (!formData.yes) newErrors.yes = "Yes value is required.";
        if (!formData.yesFluctuation) newErrors.yesFluctuation = "Yes Fluctuation is required.";
        if (!formData.yesSmall) newErrors.yesSmall = "Yes Small value is required.";
        if (!formData.yesSmallFluctuation) newErrors.yesSmallFluctuation = "Yes Small Fluctuation is required.";
        if (!formData.min_bet) newErrors.min_bet = "Minimum bet is required.";
        if (!formData.max_bet) newErrors.max_bet = "Maximum bet is required.";
        if (!formData.status) newErrors.status = "Status is required.";
        if (!formData.result) newErrors.result = "Result is required.";
        if (!formData.resultType) newErrors.resultType = "Result type is required.";
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.ToTimme) newErrors.ToTimme = "To Time is required.";
        if (!formData.fromTime) newErrors.fromTime = "From Time is required.";

        setErrorsMessage(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    // console.log("errorsmessageerrorsmessage", errorsmessage);


    const GamegetStocksList = async () => {
        try {
            const res = await adminGetAPIAuth(
                "market/getStocks"
            );

            console.log("resreswertyui", res);
            if (res?.data?.status === 200) {
                setStockList(res?.data?.data?.data);
            }
        } catch (error) {
            setStockList([]);
        }
    };


    const GameCatagoriesList = async () => {
        try {
            const res = await adminGetAPIAuth(
                "market/getStockCategory"
            );

            console.log("qwerty", res);
            if (res?.data?.status === 200) {
                setCatagoriesList(res?.data?.data?.data);
            }
        } catch (error) {
            setCatagoriesList([]);
        }
    };

    useEffect(() => {
        GameCatagoriesList();
        GamegetStocksList();
    }, []);

    const GetMarketData = async () => {
        try {
            const res = await adminGetAPIAuth(
                REPORTS?.getMarketData
            );
            console.log('resresresres', res);
            if (res?.data?.data?.success) {
                // setTableData(res?.data?.data?.data);
            } else {
                // errorToaster("Something went wrong in fetching data");
            }
        } catch (error) {
            if (error?.response?.status === 401) {

            }
            // errorToaster(error?.response?.data?.data?.message);
            // setTableData([]);
            console.log("error -------------->", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        // const selectedItem = stocklist?.find(item => item.name === value);
        // setStockLtpValue(selectedItem?.LTP);
        // console.log("selectedItem", selectedItem);
        console.log({ name, value });
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const handleInputBlur = () => {
        console.log('Input blurred');
    };

    function getGameId() {
        // console.log("gameNamegameName", gameName);
        const game = CatagoriesList?.find(g => g.name === formData.gameCate);
        // console.log("shiisfdusfhs", game);
        return game ? game._id : formData.gameCate;
    }

    const handleltpvalue = (item) => {


        alert("hii")

        console.log("qwertyu", item);
        // setStockLtpValue()


    }



    const changeStatusApi = async () => {


        const payload = {
            stockCategoryId: `${formData.gameCate}`,
            stockId: `${formData.stockCat}`,
            name: `${formData.name}`,
            min_bet: `${formData.min_bet}`,
            max_bet: `${formData.max_bet}`,
            status: `${formData.status}`,
            result: `${formData.result}`,
            from: `${formData.from}`,
            to: ` ${formData.to}`,
            no: Number(formData.no),
            noFluctuation: `${formData.noFluctuation}`,
            noSmall: `${formData.noSmall}`,
            noSmallFluctuation: `${formData.noSmallFluctuation}`,
            yes: Number(formData.yes),
            yesFluctuation: `${formData.yesFluctuation}`,
            yesSmall: `${formData.yesSmall}`,
            yesSmallFluctuation: `${formData.yesSmallFluctuation}`,
            resultType: `${formData.resultType}`,
            toTime: `${formData?.ToTimme}`,
            fromTime: `${formData?.fromTime}`,

        };
        try {

            if (validate()) {
                const res = await adminPostAPIAuth(USER.market, payload);
                if (res.status === 200) {
                    successToaster("Status Updated Successfully! ");
                    setFormData({

                        gameCate: "",
                        stockCat: "",
                        from: "",
                        to: "",
                        no: "",
                        noFluctuation: "",
                        noSmall: "",
                        noSmallFluctuation: "",
                        yes: "",
                        yesFluctuation: "",
                        yesSmall: "",
                        yesSmallFluctuation: "",
                        min_bet: "",
                        max_bet: "",
                        status: "",
                        result: "",
                        resultType: "",
                        ToTimme: "",
                        fromTime: ""


                    });

                    navigate(`/${PORTAL}/${adminData.role}/${MARKET_PAGE}`);
                    // setError("");
                    // setShow(false);
                    // updateData()
                }

            }


        } catch (error) {
            console.log("sssssserrorr", error);
            errorToaster(error?.response?.data?.data?.message);
        }
    };

    const EditchangeStatusApi = async () => {
        console.log("formData.gameCate", formData.gameCate);
        const selectedGameId = getGameId();

        // console.log("selectedGameId", selectedGameId);

        const payload = {
            stockCategoryId: `${selectedGameId}`,
            stockId: `${formData.stockCat}`,
            name: `${formData.name}`,
            min_bet: `${formData.min_bet}`,
            max_bet: `${formData.max_bet}`,
            status: `${formData.status}`,
            result: `${formData.result}`,
            from: `${formData.from}`,
            to: `${formData.to}`,
            no: `${formData.no}`,
            noFluctuation: `${formData.noFluctuation}`,
            noSmall: `${formData.noSmall}`,
            noSmallFluctuation: `${formData.noSmallFluctuation}`,
            yes: `${formData.yes}`,
            yesFluctuation: `${formData.yesFluctuation}`,
            yesSmall: `${formData.yesSmall}`,
            yesSmallFluctuation: `${formData.yesSmallFluctuation}`,
            resultType: `${formData.resultType}`,
            toTime: `${formData?.ToTimme}`,
            fromTime: `${formData?.fromTime}`,
        };

        try {

            if (validate()) {
                const res = await adminPostAPIAuth(`${USER.EditMarket}?id=${state?._id}`, payload);
                if (res.status === 200) {
                    successToaster("Edit sucessfully!");
                    GetMarketData()

                    setFormData({

                        gameCate: "",
                        stockCat: "",
                        from: "",
                        to: "",
                        no: "",
                        noFluctuation: "",
                        noSmall: "",
                        noSmallFluctuation: "",
                        yes: "",
                        yesFluctuation: "",
                        yesSmall: "",
                        yesSmallFluctuation: "",
                        min_bet: "",
                        max_bet: "",
                        status: "",
                        result: "",
                        ToTimme: "",
                        fromTime: ""

                    });

                    navigate(`/${PORTAL}/${adminData.role}/${MARKET_PAGE}`);
                }

            }
        } catch (error) {
            errorToaster(error?.response?.data?.data?.message);
        }
    };

    useEffect(() => {
        const selectedItem = stocklist?.find(item => item.name === formData.stockCat || state?.stockId);
        // console.log("asdfsafas", selectedItem);
        setStockLtpValue(selectedItem?.LTP);


        // const CatagoriesList = CatagoriesList?.find(item => item.name === formData.stockCat);

    }, [formData, state, param])

    return (
        <>
            <div className="app">
                <main className="app-main">
                    <div className="wrapper">
                        <div className="page">
                            <div className="page-inner ">
                                <header className="page-title-bar justify-content-between row align-items-center mb-2">
                                    <h1 className="page-title mb-0 text-nowrap px-0 mb-3">
                                        Market
                                    </h1>
                                </header>


                                <div className="row">

                                    <div className="market-form-container">
                                        <div className="row">
                                            <div className="col-lg-6 mb-3">

                                                <label htmlFor="gameCategory"> STOCK   </label>  {errorsmessage.stockCat && <span className="error">{`(${errorsmessage.stockCat})*`}</span>}
                                                <select
                                                    id="gameCategory"
                                                    className="form-control"
                                                    name="stockCat"
                                                    value={formData.stockCat}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select Stock</option>

                                                    {
                                                        stocklist?.map((item) => {
                                                            // console.log("item", item);
                                                            return (
                                                                <>
                                                                    {/* {console.log('is selected', item?.name === formData.gameCate)} */}
                                                                    <option
                                                                        selected={item?.name === formData.gameCate}
                                                                        value={item.name} key={item.name}>{item?.name}</option>
                                                                </>
                                                            )
                                                        })
                                                    }

                                                </select>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="gameCategory">Select Stock Category</label>
                                                {errorsmessage.gameCate && <span className="error">{`(${errorsmessage.gameCate})*`}</span>}
                                                <select
                                                    id="gameCategory"
                                                    className="form-control"
                                                    name="gameCate"
                                                    // value={formData.gameCate}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">Select a category</option>

                                                    {
                                                        CatagoriesList.map((item) => {
                                                            // console.log("item", item);
                                                            return (
                                                                <>
                                                                    {/* {console.log('is selected', item?.name === formData.gameCate)} */}
                                                                    <option
                                                                        selected={item?.name === formData.gameCate}
                                                                        value={item._id} key={item.name}>{item?.name}</option>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">Name</label>
                                                {errorsmessage.name && <span className="error">{`(${errorsmessage.name})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="Name"
                                                    className="form-control adminmarketData"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">FROM</label>
                                                {errorsmessage.from && <span className="error">{`(${errorsmessage.from})*`}</span>}
                                                <input
                                                    type="date"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="from"
                                                    value={formData.from}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">To</label>
                                                {errorsmessage.to && <span className="error">{`(${errorsmessage.to})*`}</span>}
                                                <input
                                                    type="date"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="to"
                                                    value={formData.to}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">FROM TIME</label>
                                                {errorsmessage.fromTime && <span className="error">{`(${errorsmessage.fromTime})*`}</span>}
                                                <input
                                                    type="time"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="fromTime"
                                                    value={formData.fromTime}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">To TIME</label>
                                                {errorsmessage.ToTimme && <span className="error">{`(${errorsmessage.ToTimme})*`}</span>}
                                                <input
                                                    type="time"
                                                    placeholder="Search User"
                                                    className="form-control adminmarketData"
                                                    name="ToTimme"
                                                    value={formData.ToTimme}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor=""> NO </label>
                                                {errorsmessage.no && <span className="error">{`(${errorsmessage.no})*`}</span>}
                                                <span style={{ color: "red" }}> {stockltpvalue} </span>
                                                <input
                                                    type="text"
                                                    placeholder="No (Price)"
                                                    className="form-control adminmarketData"
                                                    name="no"
                                                    value={formData.no}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO FLUCTUATION</label>
                                                {errorsmessage.noFluctuation && <span className="error">{`(${errorsmessage.noFluctuation})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="NO Fluctuation (Price)"
                                                    className="form-control adminmarketData"
                                                    name="noFluctuation"
                                                    value={formData.noFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO(SMALL)</label>
                                                {errorsmessage.noSmall && <span className="error">{`(${errorsmessage.noSmall})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="NO(Small)"
                                                    className="form-control adminmarketData"
                                                    name="noSmall"
                                                    value={formData.noSmall}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">NO (SMALL) FLUCTUATION</label>
                                                {errorsmessage.noSmallFluctuation && <span className="error">{`(${errorsmessage.noSmallFluctuation})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="No (Small) Fluctuation"
                                                    className="form-control adminmarketData"
                                                    name="noSmallFluctuation"
                                                    value={formData.noSmallFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES</label>
                                                {errorsmessage.yes && <span className="error">{`(${errorsmessage.yes})*`}</span>}
                                                <span style={{ color: "red" }}> {stockltpvalue} </span>
                                                <input
                                                    type="text"
                                                    placeholder="Yes (Price)"
                                                    className="form-control adminmarketData"
                                                    name="yes"
                                                    value={formData.yes}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES FLUCTUATION</label>
                                                {errorsmessage.yesFluctuation && <span className="error">{`(${errorsmessage.yesFluctuation})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="Yes Fluctuation (Price)"
                                                    className="form-control adminmarketData"
                                                    name="yesFluctuation"
                                                    value={formData.yesFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>
                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES(SMALL)</label>
                                                {errorsmessage.yesSmall && <span className="error">{`(${errorsmessage.yesSmall})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="Yes(Small)"
                                                    className="form-control adminmarketData"
                                                    name="yesSmall"
                                                    value={formData.yesSmall}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">YES (SMALL) FLUCTUATION</label>
                                                {errorsmessage.yesSmallFluctuation && <span className="error">{`(${errorsmessage.yesSmallFluctuation})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="Yes (Small) Fluctuation"
                                                    className="form-control adminmarketData"
                                                    name="yesSmallFluctuation"
                                                    value={formData.yesSmallFluctuation}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>


                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">MIN BET</label>
                                                {errorsmessage.min_bet && <span className="error">{`(${errorsmessage.min_bet})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="MIN BET"
                                                    className="form-control adminmarketData"
                                                    name="min_bet"
                                                    value={formData.min_bet}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>





                                            <div className="col-lg-6 mb-3">
                                                <label htmlFor="">MAX BET</label>
                                                {errorsmessage.max_bet && <span className="error">{`(${errorsmessage.max_bet})*`}</span>}
                                                <input
                                                    type="text"
                                                    placeholder="Max Bet"
                                                    className="form-control adminmarketData"
                                                    name="max_bet"
                                                    value={formData.max_bet}
                                                    onChange={handleChange}
                                                    onFocus={() => { }}
                                                    onBlur={handleInputBlur}
                                                />
                                            </div>

                                            <div className="col-lg-12">

                                                <div className="form-group ">
                                                    <label>Status:</label>
                                                    {errorsmessage.status && <span className="error">{`(${errorsmessage.status})*`}</span>}
                                                    <br />
                                                    <div className="enable-disable">
                                                        <input
                                                            type="radio"
                                                            id="statusEnable"
                                                            name="status"
                                                            value="Enable"
                                                            checked={formData.status === 'Enable'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="statusEnable">Enable</label><br />

                                                        <input
                                                            type="radio"
                                                            id="statusDisable"
                                                            name="status"
                                                            value="Disable"
                                                            checked={formData.status === 'Disable'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="statusDisable">Disable</label>

                                                        <br />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-lg-12">


                                                <div className="form-group">
                                                    <label>Result:</label>  {errorsmessage.result && <span className="error">{`(${errorsmessage.result})*`}</span>}<br />

                                                    <div className="enable-disable">
                                                        <input
                                                            type="radio"
                                                            id="resultPending"
                                                            name="result"
                                                            value="pending"
                                                            checked={formData.result === 'pending'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resultPending">Pending</label><br />

                                                        <input
                                                            type="radio"
                                                            id="resultDeclared"
                                                            name="result"
                                                            value="declared"
                                                            checked={formData.result === 'declared'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resultDeclared">Declared</label><br />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-lg-12">


                                                <div className="form-group">
                                                    <label>Result Type :</label>{errorsmessage.resultType && <span className="error">{`(${errorsmessage.resultType})*`}</span>}<br />

                                                    <div className="enable-disable">
                                                        <input
                                                            type="radio"
                                                            id="resulttypeauto"
                                                            name="resultType"
                                                            value="auto"
                                                            checked={formData.resultType === 'auto'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resulttypeauto">auto</label><br />

                                                        <input
                                                            type="radio"
                                                            id="resulttypeManual"
                                                            name="resultType"
                                                            value="Manual"
                                                            checked={formData.resultType === 'Manual'}
                                                            onChange={handleChange}
                                                        />
                                                        <label htmlFor="resulttypeManual">Manual</label><br />
                                                    </div>
                                                </div>


                                            </div>

                                            <div className="col-lg-12">
                                                <div class="page-section d-flex align-items-center m-0 flex-row-reverse col-md-auto w-auto">
                                                    {
                                                        state == null ? <button type="button" class="me-2 btn btn-primary" onClick={changeStatusApi}>Submit</button> : <button type="button" class="me-2 btn btn-primary" onClick={EditchangeStatusApi}>Edit</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};
// https://api.baaziguru.com/api/v1/user/gamereport/stage/1/6539fe66ba01c43e96234593?page=1&perPage=10&username=jon&endDate=2023-12-29
// https://api.baaziguru.com/api/v1/user/gamereport/stage/2/6539fe66ba01c43e96234593?page=1&perPage=10&username=&endDate=2023-12-29
export default AddMarket;
