import React, { useEffect, useState } from "react";
import Pagination from "../../Admincomponents/pagination/Pagination";
import { adminGetAPIAuth } from "../../AdminServices";
import { REPORTS, USER } from "../../AdminConstants/apiEndpoints";
import { useSelector } from "react-redux";
import { errorToaster } from "../../../utils/toaster";
import GRStage1Table from "./GRStage1Table";
import GRStage2Table from "./GRStage2Table";
import GRStage3Table from "./GRStage3Table";
import GRStage4Table from "./GRStage4Table";
import { returnEndDate } from "../../../utils/returnEndDate";

const GameReport = () => {
  const [tableData, setTableData] = useState([]);
  const [stage,setStage]=useState(1)
  const [loader,setLoader]=useState(false)
  const [stage4Data,setStage4Data]=useState([])

  const [selectedMatch, setSelectedMatch] = useState({id:"",name:""});
  const [selectedMarket, setSelectedMarket] = useState({id:"",name:""});

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  const [childrenList, setChildrenList] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [selectedUser, setSelectUser] = useState({});
  const [searchedUserResult, setSearchedUserResult] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);

  const [filterValues, setFilterValues] = useState({
    transactions: "",
    startDate: "",
    endDate: "",
  });

  const adminData = useSelector((state) => state?.adminData);

    // set today's date as end date
    useEffect(()=> {
      const today = new Date()
      setFilterValues((prev)=>({
        ...prev,
        endDate: returnEndDate(today)
      }))
    },[])

  const getChildrenList = async () => {
    try {
      const res = await adminGetAPIAuth(
        USER.children + "/" + adminData?._id + "?perPage=0"
      );
      if (res?.data?.status === 200) {
        setChildrenList(res?.data?.data?.data?.children);
      }
    } catch (error) {
      setChildrenList([]);
    }
  };

  useEffect(() => {
    if (adminData?._id) {
      getChildrenList();
    }
    //eslint-disable-next-line
  }, [adminData]);

  const handleChange = (e) => {
    setFilterValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleGetList = async (item) => {
    const queryParams = Object.entries(filterValues)
      .filter(([key, value]) => value !== "")
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
      setLoader(true)
    try {
      const res = await adminGetAPIAuth(
        REPORTS?.gameReportStage+`/${stage}${selectedUser?._id?"/"+selectedUser?._id:""}`+(stage===3?`/${selectedMatch?.id}`:"")
         + `?page=${currentPage}&perPage=10&username=${searchUser}` + (queryParams?"&"+queryParams:"")
        // + (selectedUser?._id ? selectedUser?._id : item._id)
      );
      if (res?.data?.data?.success) {
        setTableData(res?.data?.data?.data);
        setTotalPages(res?.data?.data?.pagination?.totalPages)
      } else {
        errorToaster("Something went wrong in fetching data");
      }
    } catch (error) {
      errorToaster(error?.response?.data?.data?.message);
      setTableData([]);
      console.log("error -------------->", error);
    }finally{
      setLoader(false)
    }
  };

  useEffect(() => {
    handleGetList();
    //eslint-disable-next-line
  }, [
    selectedUser,
    stage,
    filterValues,
    filterValues.endDate,
    filterValues.startDate,
    filterValues.transactions,
    currentPage
  ]);

  useEffect(() => {
    if (searchUser) {
      const filtered = childrenList?.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(searchUser?.toLowerCase()) ||
          item?.username?.toLowerCase()?.includes(searchUser?.toLowerCase())
      );
      setSearchedUserResult(filtered);
    } else {
      setSearchedUserResult([]);
      setSelectUser({});
      setStage(1)
    }
    //eslint-disable-next-line
  }, [searchUser]);

  const handleInputBlur = () => {
    setTimeout(() => {
      setInputFocused(false);
    }, 100);
  };

  useEffect(()=>{
    if (selectedMarket.id) {
      const filtered=tableData.filter((item)=>item._id===selectedMarket.id)
      setStage4Data([ ...filtered[0].totalLoss,...filtered[0].totalWins ])
    }
    //eslint-disable-next-line
  },[selectedMarket])
  return (
    <> 
      <div className="app">
        <main className="app-main">
          <div className="wrapper">
            <div className="page">
              <div className="page-inner ">
                <header className="page-title-bar justify-content-between row align-items-center mb-2">
                    <h1 className="page-title mb-0 text-nowrap px-0">
                      Game Report
                    </h1>
                </header>
                  {Object.keys(selectedUser).length && !selectedMatch.name ? (
                    <header className="page-title-bar">
                      <div className="page-title pt-0">
                        <nav aria-label="breadcrumb text-nowrap">
                            <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                              <li class="breadcrumb-item cursor-pointer"
                                  onClick={() => {
                                    setStage(1)
                                    setSelectUser({});
                                    setSelectedMatch({name:"",id:""});
                                    setSelectedMarket({name:"",id:""});
                                  }}>
                                <div className="d-grid align-items-center">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                                </div>
                              </li>
                                {selectedUser?.username && (
                                  <li class="breadcrumb-item"> {selectedUser?.username} </li>
                                )}{" "}
                          </ol>
                        </nav>
                      </div>
                    </header>
                  ) : (
                    ""
                  )}
                
                  {Object.keys(selectedUser).length &&
                      selectedMatch.name &&
                      !selectedMarket.name ? (
                        
                        <header className="page-title-bar">
                        <div className="page-title pt-0">
                            <nav aria-label="breadcrumb text-nowrap">
                                <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                                  <li class="breadcrumb-item cursor-pointer"   onClick={() => {
                                        setStage(1)
                                        setTableData([])
                                        setSelectUser({});
                                        setSelectedMatch({name:"",id:""});
                                        setSelectedMarket({name:"",id:""});
                                      }}>
                                    <div className="d-grid align-items-center">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                                    </div>
                                  </li>
                                    {selectedUser?.username && (
                                      <li class="breadcrumb-item cursor-pointer"
                                        onClick={()=> {
                                          setStage(2)
                                          setTableData([])
                                          setSelectedMatch({name:"",id:""});
                                          setSelectedMarket({name:"",id:""});
                                        }}
                                      > {selectedUser?.username} </li>
                                    )}
                                    <li class="breadcrumb-item"> {selectedMatch?.name}</li>
                              </ol>
                            </nav>
                          </div>
                        </header>
                  ) : (
                    ""
                  )}

                    {Object.keys(selectedUser).length &&
                      selectedMatch.name &&
                      selectedMarket.name ? (
                        
                        <header className="page-title-bar">
                        <div className="page-title pt-0">
                            <nav aria-label="breadcrumb text-nowrap">
                                <ol class="breadcrumb flex-nowrap overflow-auto pt-0 m-0">
                                  <li class="breadcrumb-item cursor-pointer"
                                      onClick={() => {
                                        setStage(1)
                                        setTableData([])
                                        setSelectUser({});
                                        setSelectedMatch({name:"",id:""});
                                        setSelectedMarket({name:"",id:""});
                                      }}>
                                    <div className="d-grid align-items-center">
                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M11.03 2.59a1.501 1.501 0 0 1 1.94 0l7.5 6.363a1.5 1.5 0 0 1 .53 1.144V19.5a1.5 1.5 0 0 1-1.5 1.5h-5.75a.75.75 0 0 1-.75-.75V14h-2v6.25a.75.75 0 0 1-.75.75H4.5A1.5 1.5 0 0 1 3 19.5v-9.403c0-.44.194-.859.53-1.144ZM12 3.734l-7.5 6.363V19.5h5v-6.25a.75.75 0 0 1 .75-.75h3.5a.75.75 0 0 1 .75.75v6.25h5v-9.403Z"></path></svg>
                                    </div>
                                  </li>
                                    {selectedUser?.username && (
                                      <li class="breadcrumb-item cursor-pointer"
                                        onClick={()=> {
                                          setStage(2)
                                          setTableData([])
                                          setSelectedMatch({name:"",id:""});
                                          setSelectedMarket("");
                                        }}
                                      > {selectedUser?.username} </li>
                                    )}
                                    <li class="breadcrumb-item cursor-pointer"
                                      onClick={() => {
                                        setStage(3)
                                        setSelectedMarket("");
                                      }}> {selectedMatch?.name}</li>
                                      <li class="breadcrumb-item"> {selectedMarket.name}</li>
                              </ol>
                            </nav>
                          </div>
                        </header>
                  ) : (
                    ""
                  )}
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="row align-items-end">
                      <div className="col-md-auto pe-md-0 mb-2 mb-md-0">
                        {/* useful do not remove */}
                        {!(stage === 3 || stage === 4) && <div className="adminSearchInput">
                          <input
                            type="text"
                            placeholder="Search User"
                            className="form-control adminFilterInp"
                            name="searchUser"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e?.target?.value)}
                            onFocus={() => setInputFocused(true)}
                            onBlur={handleInputBlur}
                          />
                          <div className="overflow-hidden">
                            {searchedUserResult?.length && inputFocused ? (
                              <ul className="list-unstyled gap-2 adminsearchDropDown overflow-Y-auto">
                                {searchedUserResult?.map((item) => (
                                  <li
                                    className="userName"
                                    onClick={() => {
                                      setSearchUser(item.username);
                                      setSelectUser(item);
                                      setStage(2)
                                      setSelectedMatch({name:"",id:""})
                                      setSelectedMarket({name:"",id:""})
                                      setSearchedUserResult([]);
                                      setCurrentPage(1)
                                      // handleGetList(item);
                                    }}
                                  >
                                    {item.username}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>}
                      </div>
                      <div className="col-md">
                        <div className="row justify-content-end mx-md-0">
                          {/* <div className="col-md-auto col-12 col-sm-4 pe-md-0 mb-2 mb-sm-0">
                            <label htmlFor="" className="form-label">Transactions</label>
                            <select name="" id="" className="form-select adminFilterInp">
                              <option value="">All</option>
                            </select>
                          </div> */}
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="startDate" className="form-label">
                              From
                            </label>
                            <input
                              type="date"
                              name="startDate"
                              id="startDate"
                              className="form-control adminFilterInp"
                              value={filterValues.startDate}
                              onChange={handleChange}
                              disabled={stage === 1 ? false : true}
                            />
                          </div>
                          <div className="col-md-auto col-6 col-sm-6 pe-md-0">
                            <label htmlFor="endDate" className="form-label">
                              To
                            </label>
                            <input
                              type="date"
                              id="endDate"
                              name="endDate"
                              className="form-control adminFilterInp"
                              value={filterValues.endDate}
                              onChange={handleChange}
                              disabled={stage === 1 ? false : true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!Object.keys(selectedUser).length ? (
                    <GRStage1Table
                      data={tableData}
                      setSelectUser={setSelectUser}
                      setStage={setStage}
                      loader={loader}
                    />
                  ) : (
                    ""
                  )}
                  {Object.keys(selectedUser).length &&
                  !selectedMatch?.name &&
                  !selectedMarket.name ? (
                    <GRStage2Table setSelectedMatch={setSelectedMatch} data={tableData} loader={loader} setStage={setStage} />
                  ) : (
                    ""
                  )}
                  {selectedMatch?.name && !selectedMarket.name ? (
                    <GRStage3Table setSelectedMarket={setSelectedMarket} loader={loader} data={tableData} />
                  ) : (
                    ""
                  )}
                  {selectedMarket.name ? <GRStage4Table loader={loader} data={stage4Data}/> : ""}
                  {
                    totalPages > 1 ? 
                    <Pagination
                      totalPages={totalPages}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    /> : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
// https://api.baaziguru.com/api/v1/user/gamereport/stage/1/6539fe66ba01c43e96234593?page=1&perPage=10&username=jon&endDate=2023-12-29
// https://api.baaziguru.com/api/v1/user/gamereport/stage/2/6539fe66ba01c43e96234593?page=1&perPage=10&username=&endDate=2023-12-29
export default GameReport;
